/*
 * Project: project
 * File Created: 2019-10-22
 * Author: zhoud
 * Last Modified: 2019-10-23
 * Modified By: zhoud
 * Desc: 全局样式
 */
@import url(./variable.css);

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #bbb;
  box-shadow: inset 0 0 6px #1890ff;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}

/* 内容主体背景色 */
.main-content {
  background: var(--contentBackground) !important;
}

.menu-background {
  background: var(--menuBackground) !important;
}

/* 菜单主色 */
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: var(--menuBackground) !important;
}

/* 菜单未选中项文字颜色 */
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a {
  color: var(--menuTextColor) !important;
}

/* 菜单选中项背景颜色 */
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: var(--menuSelectedBackgound) !important;
}

/* 菜单选中项文字颜色 */
.ant-menu-dark .ant-menu-item-selected .anticon+span {
  color: var(--menuSelectedTextColor) !important;
}

/* logo和展开图标的颜色 */
.logo {
  color: var(--menuIconColor) !important;
}

/* 用户信息面板 */
.user-info {
  background: var(--userInfoBackground) !important;
  color: var(--userInfoTextColor) !important;
}

.ant-btn.ant-btn-primary {
  background: var(--antdPrimaryButtonBackground) !important;
  color: var(--antdPrimaryButtonTextColor) !important;
  border-color: var(--antdPrimaryButtonBorderColor) !important;
}

.selectedRoleRow {
  background: blue;
}

.ant-descriptions-item-content {
  text-align: center;
}
:root {
  /* 全局 */

  /* 内容区背景色 */
  --contentBackground: rgb(238, 238, 238);

  /* 菜单 */

  /* 菜单主色 */
  --menuBackground: rgb(0, 21, 41);
  /* 菜单未选中项文字颜色 */
  --menuTextColor: rgba(255, 255, 255, 0.65);
  /* 菜单选中项背景颜色 */
  --menuSelectedBackgound: #1890ff;
  /* 菜单选中项文字颜色 */
  --menuSelectedTextColor: #fff;
  /* logo和展开图标的颜色 */
  --menuIconColor: #fff;

  /* 用户信息面板 */

  /* 背景色 */
  --userInfoBackground: #000000;
  /* 文字颜色 */
  --userInfoTextColor: #ffffff;

  /* antd button */

  /* primary button */
  --antdPrimaryButtonBackground: #40a9ff;
  --antdPrimaryButtonTextColor: #fff;
  --antdPrimaryButtonBorderColor: #40a9ff;
}
